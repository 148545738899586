// Constants
import { ADDONS, DEFAULT_LANGUAGE } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import { getConfigById } from '@/services/config'
import { updateEveryTranslations } from '@/services/translate'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get, cloneDeep, isNil } from 'lodash'

export default {
  name: 'ConfigMenu',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        additionalLanguages: [],
        allowCreateMenu: true,
        currency: null,
        defaultLanguage: DEFAULT_LANGUAGE,
        showCurrency: true
      },
      formFieldsValidations: {
        defaultLanguage: {
          required: 'Campo obligatorio'
        }
      },
      // Others
      currencyData: [],
      languagesData: [],
      optionsShowCurrencies: [
        {
          value: true,
          label: 'Si'
        },
        {
          value: false,
          label: 'No'
        }
      ],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    /**
     * Get additionalLanguages removing default language
     *
     * @return array additionalLanguages
     */
    availableLanguages() {
      return this.languagesData && Array.isArray(this.languagesData) ? this.languagesData : []
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('company', ['updateCompanyConfigsData']),
    /**
     * Triggered when we change "defaultLanguage"
     * Remove selected language from "additionalLanguages"
     *
     * @param {string} value - selected lang
     */
    handleChangeDefaultLanguage(value) {
      this.formFields.additionalLanguages = this.formFields.additionalLanguages.filter(
        (lang) => lang !== value
      )
    },
    /**
     * Handle the change in the currency field
     *
     * @param {string} code - currency code
     */
    handleChangeCurrency(code) {
      const selectedCurrency = this.currencyData.find((item) => {
        return item.code === code
      })
      this.formFields.currency = selectedCurrency
    },
    /**
     * Obtenemos o establecemos todos los datos
     * necesarios para vista en su carga inicial
     */
    async getEveryNeededData() {
      try {
        // Loading
        this.processingRequest = true
        // Get data
        await this.setLanguagesData()
        await this.setCurrencies()
        // Set fields
        this.setFormFieldsValues()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const companyConfig = get(this.companyAddonsSetupByUser, ADDONS.company, {})

      // Datos "company"
      this.formFields = {
        allowCreateMenu: get(companyConfig, 'allowCreateMenu', true),
        additionalLanguages: cloneDeep(companyConfig.additionalLanguages) || [],
        currency: companyConfig.currency || null,
        defaultLanguage: companyConfig.defaultLanguage || DEFAULT_LANGUAGE,
        showCurrency: isNil(companyConfig.showCurrency)
          ? this.formFields.showCurrency
          : companyConfig.showCurrency
      }
    },
    /**
     * Set currencies data
     */
    async setCurrencies() {
      const { data } = await getConfigById('currency')
      this.currencyData = data
    },
    /**
     * Set languages data from DB
     */
    async setLanguagesData() {
      const { data } = await getConfigById('languages')
      this.languagesData = data.sort((a, b) => a.name.localeCompare(b.name, 'es'))
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Actualizamos configuraciones
      await this.updateCompanyConfigsData({
        id: this.companyData.id,
        addOns: [
          {
            id: ADDONS.company,
            configFields: this.formFields
          }
        ]
      })

      // Si hay más de un idioma, realizamos los cambios
      // en las cartas, categorías, productos...
      const { ok } = await updateEveryTranslations('company', this.companyData.id, {
        defaultLanguage: this.formFields.defaultLanguage,
        additionalLanguages: this.formFields.additionalLanguages
      })

      if (!ok) {
        throw new Error('Hubo un error al intentar actualizar los idiomas.')
      }

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations: {
    formFields: {
      additionalLanguages: {},
      defaultLanguage: {
        required
      },
      allowCreateMenu: {},
      currency: {},
      showCurrency: {}
    }
  }
}
