export default {
  name: 'CardContainerElement',
  props: {
    verticalAlign: {
      type: String,
      default: 'start'
    },
    widthLeft: {
      type: Number,
      default: 5
    },
    widthRight: {
      type: Number,
      default: 7
    },
    moreInfo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMoreInfo: false
    }
  }
}
